import { ForwardedRef, forwardRef } from 'react'
import { Button, FormLayout, GSDItemType, Icon, Input, Loading, Modal, Select, UploadImage, useGSD } from 'shared';
import styled from 'styled-components'

interface IAppItem {
    id?: number
    title: string
    logo: string
    online: boolean
    status: string
}

interface AppsManagmentsProps {

}

interface AppsManagmentsRef {

}

type GSDAppItem = GSDItemType<IAppItem>;

function AppsManagments(props: AppsManagmentsProps, ref: ForwardedRef<AppsManagmentsRef>) {
    const appsGSD = useGSD<IAppItem>({ path: 'apps', initialLoad: true })
    const { list, loading } = appsGSD;
    return (
        <AppsManagmentsContainer>
            <Loading loading={loading} />
            {list.filter((item) => item.$saved).map((item) => <AppItem key={item.$key} app={item} />)}
            <NewAppItem onClick={() => appsGSD.add({ title: 'New App title', logo: '', online: false, status: 'new' }).edit()} />
            <Modal
                {...appsGSD.modal('edit')}
                header={<b>App</b>}
                footer={(app: GSDAppItem) => app && (
                    <>
                        <Button type='Primary' onClick={() => app.saveAndReloadData().then(appsGSD.modals.edit.hide)}>Valider</Button>
                        <Button onClick={() => app.reset().deleteIfNotSaved().then(appsGSD.modals.edit.hide)}>Annuler</Button>
                    </>
                )}
                loading={(item: GSDAppItem) => loading || (item && item.$loading)}
            >
                {(app: GSDAppItem) => app && (
                    <FormLayout columns='1' gap='15'>
                        <Input label="Title" {...app.bind('title')} />
                        <Input label="URL" rightAddon=".app.sim-software.net" {...app.bind('name')} />
                        <UploadImage label="Logo" {...app.bind('logo')} width={150} />
                        <Input label="Online" type='checkbox' {...app.bind('online', { checkbox: [1, 0] })} />
                        <Select label="Online" options={[{ value: 1, label:'Online' }, { value: 0, label:'Offline' }]} {...app.bind('online')}/>
                        <Select label="Status" options={['New', 'draft', 'test']} {...app.bind('status')}/>
                        <pre>{JSON.stringify(app, null, 2)}</pre>
                    </FormLayout>
                )}
            </Modal>
        </AppsManagmentsContainer>
    )
}

const AppsManagmentsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 15px;
`;

function AppItem({ app }: { app: GSDAppItem }) {
    return (
        <AppItemContainer>
            <Loading loading={app.$loading} />
            <div className='app-title'>{app.title}</div>
            <div className='app-actions'>
                <Button icon="delete" onClick={() => app.deleteWithConfirmation("Voulez vous vraiment supprimer cette application?")} padding="5px" />
                <Button type='Primary' icon="start" onClick={app.edit} padding="5px 10px">Start app</Button>
                <Button icon="edit" onClick={app.edit} padding="5px" />
            </div>
        </AppItemContainer>
    )
}

function NewAppItem({ onClick }: { onClick: any }) {
    return (
        <AppItemContainer className='new-app' role='button' onClick={onClick}>
            <Icon icon='add' size='62px' />
            <span>Create new app</span>
        </AppItemContainer>
    )
}

const AppItemContainer = styled.div`
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    flex: 1;
    max-width: 250px;
    min-width: 250px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    position: relative;

    .app-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        flex: 1;
    }

    .app-actions {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &.new-app {
        cursor: pointer;
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    }
`;




export default forwardRef(AppsManagments)
