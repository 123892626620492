import styled from 'styled-components';
import useUser from './../../hooks/useUser';

function Header() {
  return (
    <HeaderDiv>
      <Avatar />
      <span style={{ flex: 1 }} />
      <Avatar align='right' />
    </HeaderDiv>
  )
}

interface IAvatarProps {
  align?: 'left' | 'right'
}

function Avatar(props: IAvatarProps) {
  const { user } = useUser();
  if (!user) return null;
  return (
    <AvatarDiv className={props.align === 'right' ? 'right' : 'left'}>
      <img src={user.photo} alt={user.name} referrerPolicy="no-referrer"/>
      <div>
        <b>{user.name}</b>
        <small>{user.login}</small>
      </div>
    </AvatarDiv>
  )
}

const AvatarDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    &.right {
      flex-direction: row-reverse;
      text-align: right;
    }
`;

const HeaderDiv = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: var(--secondary-medium-gray);
    box-shadow: 0 1px 6px 0px var(--secondary-smoke-gray);
`;

export default Header
