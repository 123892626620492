import React, { ForwardedRef, PropsWithChildren, ReactNode, useEffect, useImperativeHandle, useState } from "react"
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { Loading } from 'shared';

interface IModalProps {
    header?: ReactNode | ((data: any) => ReactNode)
    footer?: ReactNode | ((data: any) => ReactNode)
    content?: ReactNode | ((data: any) => ReactNode)
    children?: ReactNode | ((data: any) => ReactNode)
    closeButton?: Boolean
    loading?: Boolean | ((data: any) => Boolean)
    width?: number | string
    duration?: number
    data?: any
}

export type IModalRef = {
    show: (options?: IModalProps) => Promise<any>;
    hide: () => void;
};

function Modal(props: IModalProps, ref: ForwardedRef<IModalRef>) {
    const [visible, setVisible] = useState<Boolean>(false);
    const [callback, setCallback] = useState<Function | null>(null);
    const [isRemoved, setIsRemoved] = useState<Boolean>(false);
    const [options, setOptions] = useState<IModalProps>({});
    const duration = (options.duration ?? props.duration) ?? 150;
    const Methods = {
        show(options?: IModalProps) {
            if (options) setOptions(options)
            setVisible(true);
            return new Promise((resolve) => setCallback(() => resolve));
        },
        hide() {
            setVisible(false);
        }
    }

    useImperativeHandle(ref, () => Methods);

    useEffect(() => {
        if (visible) {
            setIsRemoved(false)
        } else {
            setTimeout(() => {
                setIsRemoved(true)
                if (callback) callback();
            }, duration)
        }
    }, [callback, duration, visible])

    if (isRemoved && !visible) return null;

    const renderContent = (content: any) => {
        if (typeof content === 'function') {
            return content(options.data ?? props.data);
        }
        return content;
    }
    const CloseButton = (options.closeButton ?? props.closeButton) ? <ModalCloseBtn onClick={Methods.hide} /> : null;
    const content = (
        <ModalContainer duration={duration} className={(!isRemoved && visible) ? 'ModalContainer-visible' : 'ModalContainer-notvisible'}>
            <ModalDiv width={options.width}>
                <Loading loading={renderContent(props.loading) === true} />
                {(options.header ?? props.header) ? (
                    <ModalHeader>
                        {renderContent(options.header ?? props.header)}
                        {CloseButton}
                    </ModalHeader>
                ) : CloseButton ? (
                    <div style={{ position: 'relative' }}>
                        {CloseButton}
                    </div>
                ) : null}
                <ModalBody hasHeader={!!(options.header ?? props.header)}>
                    {renderContent(options.content ?? props.children)}
                </ModalBody>
                {(options.footer ?? props.footer) ? (
                    <ModalFooter>
                        {renderContent(options.footer ?? props.footer)}
                    </ModalFooter>
                ) : null}
            </ModalDiv>
        </ModalContainer>
    )
    return ReactDOM.createPortal(content, document.body)
}

export default React.forwardRef(Modal)

const ModalContainer = styled.div<{ duration: number }>`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    -webkit-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
    -moz-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
    -ms-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
    -o-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
    transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};

    & > div {
        -webkit-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
        -moz-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
        -ms-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
        -o-transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
        transition: ${(props: any) => 'all ' + props.duration + 'ms ease-in-out'};
        -moz-transform: translateY(100px);
        -webkit-transform: translateY(100px);
        -o-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
    }

    &.ModalContainer-visible {
        opacity: 1;
    }

    &.ModalContainer-visible > div {
        opacity: 1;
        
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
`;

const ModalDiv = styled.div<{ width?: number | string }>`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #fff;
    min-width: 350px;
    max-width: 80%;
    width: ${(props) => (props.width ? (props.width + (`${props.width}`.toLowerCase().includes('px') ? '' : 'px')) : '700px')};
    border-radius: 10px;
    min-height: 200px;
    padding: 20px;
    max-height: 90vh;
    gap: 20px;
`;

const ModalBody = styled.div<{ hasHeader: boolean }>`
    flex: 1;
    overflow-y: auto;
    margin-right: ${(props) => props.hasHeader ? '0' : '20px'};
    margin-top: ${(props) => props.hasHeader ? '0' : '-20px'};
`;


const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;


const ModalFooter = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
`;

const ModalCloseBtn = styled.button`
    all: unset;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 18px;
    height: 18px;
    z-index: 10;
    &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 2px;
        background-color: #000;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;
