import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import backend from "services/backend";
import Loading from "shared/Utils/Loading";

export interface IApp {
  appid: string
  title: string
}

export type IAppState = IApp | null

export interface IAppRespState {
  app: IApp
}

export async function loadAppData(appid: string): Promise<IAppState> {
  const { data } = await backend.post<IAppRespState>('/get-app', { appid })
  return data.app || null
}

export interface IAppRespContext {
  app: IAppState
  loading: Boolean
  reload: Function
}

export const SelectedAppContext = createContext<IAppRespContext>({
  app: null,
  loading: false,
  reload: () => null
});

export function useSelectedAPP() {
  return useContext(SelectedAppContext);
}

export function SelectedAPP({ children }: any) {
  const [app, setApp] = useState<IAppState>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const params = useParams<{ appid?: string }>();

  const reload = async () => {
    setLoading(true);
    try {
      if (params?.appid) {
        const data = await loadAppData(params.appid);
        setApp(data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    reload();
  }, [params?.appid])

  return (
    <SelectedAppContext.Provider value={{ app, loading, reload }}>
      <Loading loading={loading}>
        {children}
      </Loading>
    </SelectedAppContext.Provider>
  )
}

export default useSelectedAPP;
