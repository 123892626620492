import AppsManagments from 'components/AppsManagments';

function Apps() {
  return (
    <div>
      <AppsManagments/>
    </div>
  )
}

export default Apps
