import { ReactNode, useEffect, useRef, useState } from "react";
import { createRoot } from 'react-dom/client';
import useList, { ListItemType } from "./useList";
import { IModalRef } from "shared/Modal/Modal";
import { api, useBindableState, Modal, Button } from 'shared';
import { AxiosError } from "axios";
import { IUseBindableState } from "./useBindableState";

interface IGSDOptions<ItemType extends {} = any, ExtraItemType extends {} = any> {
    path: string
    initialLoad?: boolean
    idField?: string
    initItem?: ((item: GSDItemType<ItemType & ExtraItemType>) => GSDItemType<ItemType & ExtraItemType>)
    initFilter?: ((filter: FilterType) => void)
}

interface IGSDOptionsRequired extends Required<Pick<IGSDOptions, 'initialLoad' | 'idField'>> { }
const defaultOptions: IGSDOptionsRequired = {
    initialLoad: false,
    idField: 'id'
}

interface ItemEdit {
    $saved: boolean
    $loading: boolean
    edit: () => void
    api: (<T extends {}>(name: string, body?: any) => Promise<T>)
    save: (options?: ISaveActionoptions) => Promise<GSDAPIResponse>
    saveAndReloadData: (options?: ISaveActionoptions, page?: number) => Promise<any>
    deleteWithConfirmation: (message: ReactNode) => Promise<boolean>
    delete: (options?: IDeleteActionoptions) => Promise<GSDAPIResponse>
    deleteIfNotSaved: () => Promise<boolean>
    show: (modalName: string) => void
}

export type GSDItemType<ItemType extends {}> = ItemType & ItemEdit & ListItemType<ItemType, ItemEdit>;

interface GSDAPIResponse extends Object {
    success?: boolean
    item?: any
}

interface GSDAPIGETResponse<ItemType extends {}> extends Object {
    success: boolean
    rows: ItemType[]
    total: number
}

interface ISaveActionoptions extends Object {
    fields?: string[]
}

interface IDeleteActionoptions extends Object {
    fields?: string[]
}

interface FilterType extends IUseBindableState {
    loadData: (page: number) => Promise<void>
}

function useGSD<ItemType extends {}, ExtraItemType extends {} = {}>(_options: IGSDOptions<ItemType, ExtraItemType>) {
    const options: IGSDOptions & IGSDOptionsRequired = { ...defaultOptions, ..._options }
    const modalRef = useRef<any>(null);
    const [modals, setModals] = useState<{ [key: string]: IModalRef }>({});
    const [currentItem, setCurrentItem] = useState<GSDItemType<ItemType> | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const _filter = useBindableState({});
    const delModalRef = useRef<IModalRef>(null);

    const list = useList<ItemType, ItemEdit & ExtraItemType>({
        singleCheck: true,
        initItem: (item) => {
            item.$saved ??= false;
            item.$loading ??= false;
            item.edit = () => {
                setCurrentItem(item);
                return item.show('edit');
            }

            item.api = <T extends {} = any>(name: string, body?: any): Promise<T> => {
                item.set({ $loading: true });
                return new Promise((resolve, reject) => {
                    apiCall<T>(name, { ...body, item })
                        .then((data) => {
                            if (data && (data as any).item) {
                                item.set((data as any).item);
                            }
                            resolve(data);
                            return data;
                        })
                        .catch((error: AxiosError<any>) => {
                            item.set({ $errors: error.response?.data?.error })
                            reject(error);
                        })
                        .finally(() => {
                            item.set({ $loading: false });
                        });
                });
            }

            item.save = (_options?: ISaveActionoptions) => {
                return item.api<GSDAPIResponse>('save', _options);
            }

            item.saveAndReloadData = (_options?: ISaveActionoptions, page: number = 1) => {
                return item.save(_options).then(() => loadData(page));
            }

            item.deleteWithConfirmation = async (message: ReactNode = 'Do you really want to delete this application?'): Promise<boolean> => {
                delModalRef.current?.show({
                    width: 350,
                    content: (
                        <div className="gsd-alert-message">
                            {message}
                        </div>
                    ),
                    footer: (
                        <>
                            <Button onClick={() => item.delete().then(() => loadData(1)).then(delModalRef.current?.hide)} type="Primary">Supprimer</Button>
                            <Button onClick={delModalRef.current?.hide}>Annuler</Button>
                        </>
                    )
                });
                return true;
            }

            item.delete = (_options?: IDeleteActionoptions) => {
                return item.api<GSDAPIResponse>('delete', _options);
            }

            item.deleteIfNotSaved = async (_options?: IDeleteActionoptions) => {
                if (!item.$saved) {
                    item.deleteFromTheList();
                    return true;
                }
                return false;
            }

            item.show = (modalName: string) => {
                if (modals[modalName]) {
                    modals[modalName].show({ data: item });
                }
            }
            if (options.initItem) item = options.initItem(item);
            return item;
        }
    });

    useEffect(() => {
        const elm = document.createElement('div');
        document.body.appendChild(elm)
        const root = createRoot(elm);
        root.render(<Modal
            ref={delModalRef}
            closeButton
            header={<b>Supprimer</b>}
        />);
        return () => {
            document.body.removeChild(elm);
        }
    }, [])


    const close = () => {
        setCurrentItem(null);
    }

    const loadData = async (page: number) => {
        try {
            const filterData = await filter.getData();
            const data = await apiCall<GSDAPIGETResponse<ItemType>>('get', { page, filter: filterData })
            list.reset(data.rows.map((item: any) => ({ ...item, $saved: true })));
        } catch (error) {

        }
    }

    const filter: FilterType = { ..._filter, loadData };

    useEffect(() => {
        if (options.initFilter) {
            options.initFilter(filter);
        }
        if (options.initialLoad) {
            loadData(1);
        }
    }, []);

    const modal = (name: string) => {
        if (!modalRef.current?.$ref) {
            if (!modalRef.current) modalRef.current = {};
            modalRef.current.$ref = (mdl: IModalRef) => {
                if (mdl) {
                    setModals((m) => m[name] ? m : ({ ...m, [name]: mdl }));
                }
            }
        }
        return {
            ref: modalRef.current.$ref
        }
    }

    const apiCall = async <T extends {} = any>(name: string, body: any = {}) => {
        setLoading(true);
        try {
            const { data } = await api.post<T>(options.path + '/' + name, body);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    return {
        list,
        loadData,
        loading,
        close,
        filter,
        add: list.add,
        modal,
        modals,
        api: apiCall,
        currentItem: currentItem ? list.find(elm => elm === currentItem) : null
    }
}

export default useGSD;