import { useState } from 'react';
import backend from 'services/backend';

type Obj = { [key: string]: any };
type APIMethod = 'get' | 'post' | 'delete' | 'put' | 'patch';

interface IAPIOption {
  setErrors?: Function;
}

interface IUserAPI<T> {
  result?: T;
  loading: Boolean;
  errors: Obj;
  reset: () => void;
  send: (data?: Obj, callback?: (error: Obj | null, data: any | null) => void) => Promise<T>;
}

function useAPI<T>(method: APIMethod, path: string, data?: Obj, options?: IAPIOption): IUserAPI<T> {
  const [loading, setLoading] = useState<Boolean>(false);
  const [result, setResult] = useState<T>();
  const [errors, setErrors] = useState<Obj>({});

  const send = (sendData?: Obj, callback?: Function): Promise<T> => {
    setLoading(true);
    return backend[method](path, { ...data, ...sendData })
      .then((res) => res.data)
      .then((data) => {
        setResult(data ?? null)
        if(callback) callback(null, data ?? null)
        return data;
      })
      .catch((err) => {
        const { error } = err.response.data;
        if (error) {
          setErrors(error);
          if (options && options.setErrors) {
            options.setErrors(error);
          }
        }
        if(callback) callback(error, null)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setResult(undefined)
    setErrors({})
  }

  return { loading, result, errors, reset, send };
}

export function usePOST<T>(path: string, data?: Obj, options?: IAPIOption) {
  return useAPI<T>("post", path, data, options)
}

export function useGET<T>(path: string, options?: IAPIOption) {
  return useAPI<T>("get", path, options)
}

export function useDELETE<T>(path: string, data?: Obj, options?: IAPIOption) {
  return useAPI<T>("delete", path, data, options)
}

export function usePUT<T>(path: string, data?: Obj, options?: IAPIOption) {
  return useAPI<T>("put", path, data, options)
}

export function usePATCH<T>(path: string, data?: Obj, options?: IAPIOption) {
  return useAPI<T>("patch", path, data, options)
}

export default useAPI;
