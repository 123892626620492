import styled from "styled-components"
import { IconButton } from "../Utils/Icon"
import { IInputWrapperProps } from "./Input"
import { PropsWithChildren, useEffect, useState } from "react"
import Label from "./Label"

interface IUploadImageProps extends IInputWrapperProps {
    value?: string
    onChange?: (url: string) => void
    onSelect?: (urls: string[]) => void
    onDelete?: () => void
    width?: number
    height?: number
    selectOnly?: boolean
    selectMulti?: number
}

function uploadFile(file?: File): Promise<{ success: Boolean, urls?: string[] } | false> {
    if (!file) return Promise.resolve(false);
    return uploadFiles([file]);
}

function uploadFiles(files?: File[]): Promise<{ success: Boolean, urls?: string[] } | false> {
    if (!files || !files.length) return Promise.resolve(false);
    const formData: FormData = new FormData();
    files.forEach(file => formData.append("files", file));
    const token = localStorage.getItem('token');
    return fetch("/api/upload", {
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
}

function getImageFile(): Promise<File | undefined> {
    return new Promise((resolve) => {
        const input: HTMLInputElement = document.createElement("input");
        input.type = "file";
        input.accept = "image/jpeg, image/png";
        input.addEventListener("change", () => {
            if (input.files?.[0]) {
                resolve(input.files?.[0])
            } else {
                resolve(undefined)
            }
        });
        input.click();
    })
}

function getImageFiles(max: number): Promise<File[]> {
    return new Promise((resolve) => {
        const input: HTMLInputElement = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.accept = "image/jpeg, image/png";
        input.addEventListener("change", () => {
            if (input.files) {
                resolve(Array.from(input.files as FileList).splice(0, max))
            } else {
                resolve([]);
            }
        });
        input.click();
    })
}

function UploadImage({ value, onChange, selectOnly, selectMulti, onSelect, onDelete, ...props }: IUploadImageProps) {
    const [url, setUrl] = useState<string>('');

    useEffect(() => {
        if (value) {
            setUrl(value);
        } else {
            setUrl('');
        }
    }, [value])

    const handleClick = () => {
        if (selectMulti) {
            getImageFiles(selectMulti)
                .then(uploadFiles)
                .then((data) => {
                    if (data && data.success && data.urls) {
                        if (!selectOnly) {
                            setUrl(data.urls[0]);
                        }
                        if (data.urls && onSelect) {
                            onSelect(data.urls)
                        }
                    }
                });
        } else {
            getImageFile()
                .then(uploadFile)
                .then((data) => {
                    if (data && data.success && data.urls) {
                        if (!selectOnly && data.urls.length) {
                            setUrl(data.urls[0]);
                        }
                        if (data.urls && data.urls.length && onChange) {
                            onChange(data.urls[0])
                        }
                        if (data.urls && onSelect) {
                            onSelect(data.urls)
                        }
                    }
                });
        }
    }

    const handleDelete = () => {
        if(onDelete) onDelete();
        setUrl('');
    }

    return (
        <UploadImageContainer {...props} className="UploadImage" >
            {url ? (
                <ImageViewerContainer>
                    <ImageViewer width={props.width} height={props.height} src={url} alt="image" />
                    <DeleteBtn icon="close" onClick={handleDelete} />
                </ImageViewerContainer>
            ) : (
                <IconButtonWrapper {...props}>
                    <IconButton icon="cloud_upload" onClick={handleClick} />
                </IconButtonWrapper>
            )}
        </UploadImageContainer>
    )
}

const ImageViewerContainer = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
`;

const DeleteBtn = styled(IconButton)`
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
    z-index: 10 !important;
    border-radius: 50% !important;
    background-color: rgba(255,255,255,0.8) !important;
    font-size: 12px !important;
    width: 24px !important;
    height: 24px !important;
`;

const ImageViewer = styled.img<IUploadImageProps>`
    ${(props) => props.height ? 'height: ' + props.height + 'px;' : ''}
    ${(props) => props.width ? 'width: ' + props.width + 'px;' : ''}
    padding: 2px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    align-self: start;
    max-width: 100%;
`;

const IconButtonWrapper = styled.div<IUploadImageProps>`
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    ${(props) => props.height ? 'height: ' + props.height + 'px;' : ''}
    ${(props) => props.width ? 'width: ' + props.width + 'px;' : ''}
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    span {
        font-size: 40px;
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

function InputWrapperImage({ label, error, span, children }: PropsWithChildren<IInputWrapperProps>) {
    return (
        <Label label={label} error={error} span={span}>
            {children}
            {error ? <small className='app-input-error-message'>{error}</small> : null}
        </Label>
    )
}

const UploadImageContainer = styled(InputWrapperImage) <IUploadImageProps>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
`;

interface IUploadImagesProps extends IInputWrapperProps {
    value?: string[]
    onChange?: (urls: string[]) => void
    max?: number
    width?: number
    height?: number
}

export function UploadImages({ value, onChange, max, label, error, ...props }: IUploadImagesProps) {
    const defaultMax = 10;
    return (
        <InputWrapperImage {...props} label={label} error={error}>
            <UploadImagesContainer>
                {value?.map((url, i) => <UploadImage key={url + ':' + i} value={url} {...props} onDelete={() => {
                    value.splice(i, 1);
                    if(onChange) {
                        onChange([...value])
                    }
                }} />)}
                {(value?.length || 0) >= (max || defaultMax) ? null : <UploadImage {...props} onSelect={urls => onChange && onChange([...(value || []), ...urls])} selectOnly selectMulti={(max || defaultMax) - (value?.length || 0)} />}
            </UploadImagesContainer>
        </InputWrapperImage>
    )
}

const UploadImagesContainer = styled.div<IUploadImagesProps>`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;


export default UploadImage
