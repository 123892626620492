import { Navigate, NavigateProps, To, useParams } from "react-router-dom"

function MyNavigate(props: NavigateProps) {
    const params = useParams();

    let to: To = props.to;

    Object.entries(params)
        .forEach(([key, value]) => {
            if (typeof to === "string" && typeof value === 'string') {
                to = to.replace(':' + key, value);
            }
        })

    return <Navigate {...props} to={to} />
}

export default MyNavigate
