import { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import Label from "./Label";
import './Style.scss';
import Icon from "../Utils/Icon";

interface IInputProps extends IInputWrapperProps {
  placeholder?: string;
  type?: string;
  name?: string;
  value?: string | number;
  checked?: boolean;
  rows?: number;
  onChange?: (event: any) => void
}

function Input({ onChange, value, checked, type, placeholder, rows, name, ...props }: IInputProps) {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current && onChange) {
      ref.current.addEventListener('change', onChange)
    }
    return () => {
      if (ref.current && onChange) {
        ref.current.removeEventListener('change', onChange)
      }
    }
  }, [ref.current, onChange])

  const InputName = type?.toLowerCase() === 'textarea' ? 'textarea' : 'input';

  return (
    <InputWrapper {...props}>
      <InputName
        ref={ref}
        onChange={onChange}
        type={type}
        name={name}
        placeholder={placeholder}
        checked={checked}
        value={value}
        rows={rows}
        className={`${props.error ? 'has-error' : ''} app-input`.trim()}
      />
    </InputWrapper>
  );
}

export interface IInputWrapperProps {
  className?: string;
  label?: string | boolean;
  error?: string | boolean;
  rightIcon?: string | ReactNode;
  leftIcon?: string | ReactNode;
  rightAddon?: string | ReactNode;
  leftAddon?: string | ReactNode;
  span?: string | number;
}

export function InputWrapper({ children, label, rightIcon, leftIcon, rightAddon, leftAddon, error, className, span }: PropsWithChildren<IInputWrapperProps>) {
  return (
    <Label label={label} error={error} span={span}>
      <div className={`app-input-parent ${error ? 'has-error' : ''} ${className ?? ''}`}>
        {leftAddon ? <span className='app-input-addon left-addon'>{leftAddon}</span> : null}
        <div className="app-input-control">
          {rightIcon && !error ? <span className='app-input-icon right-icon'><Icon icon={rightIcon} /></span> : null}
          {error ? <span className='app-input-icon right-icon error-icon'><Icon icon="error" /></span> : null}
          {leftIcon ? <span className='app-input-icon left-icon'><Icon icon={leftIcon} /></span> : null}
          {children}
        </div>
        {rightAddon ? <span className='app-input-addon right-addon'>{rightAddon}</span> : null}
      </div>
      {error && typeof error === 'string' ? <small className='app-input-error-message'>{error}</small> : null}
    </Label>
  )
}

export default Input;
