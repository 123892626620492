import React from 'react';
import styled from 'styled-components';

interface ILoading {
  loading?: Boolean;
  fullScreen?: Boolean;
}

const LoadingWrapper = styled.div<ILoading>`
  position: relative;
  height: ${(props) => props.fullScreen ? '100vh' : 'auto'};
  width: ${(props) => props.fullScreen ? '100vw' : 'auto'};
`;

function hex2rgb(hex) {
  const r = parseInt(hex.substring(1,3), 16);
  const g = parseInt(hex.substring(3,5), 16);
  const b = parseInt(hex.substring(5,7), 16);
  return `${r}, ${g}, ${b}`;
}

const LoadingMessage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border-radius: 5px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: var(--secondary-gray);
    opacity: 0.5;
  }
`;

function Loading(props: React.PropsWithChildren<ILoading>) {
  if (props.fullScreen) {
    return (
      <LoadingWrapper {...props}>
        {props.loading ? <LoadingMessage><CustomLoader/></LoadingMessage> : null}
        {props.children}
      </LoadingWrapper>
    );
  }
  return (
    <>
      {props.loading ? <LoadingMessage><CustomLoader/></LoadingMessage> : null}
      {props.children}
    </>
  );
}



function CustomLoader() {
  return (
      <LoaderDiv>
          <div />
          <div />
          <div />
          <div />
      </LoaderDiv>
  )
}

const LoaderDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  & div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: var(--primary-blue, #0086c5);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
      0% {
          transform: scale(0);
      }
      100% {
          transform: scale(1);
      }
  }
  @keyframes lds-ellipsis3 {
      0% {
          transform: scale(1);
      }
      100% {
          transform: scale(0);
      }
  }
  @keyframes lds-ellipsis2 {
      0% {
          transform: translate(0, 0);
      }
      100% {
          transform: translate(24px, 0);
      }
  }
`;


export default Loading;
