import { useState } from "react";
import { IBindOptions, createBindFunction } from "./useBindableState";

export type ListItemType<ItemType extends {}, ItemTypeReady extends {} = {}> = ItemType & {
    bind(name: string, options?: IBindOptions): object;
    reset(): ItemType & ItemTypeReady & ListItemType<ItemType, ItemTypeReady>;
    set(data?: any): void;
    toJSON(): void;
    deleteFromTheList(): void;
    check(checked: boolean): void
    toggleCheck(): void
    $checked: boolean
    $key: string
    $errors?: any
    $original?: ItemType
    setError: (name: string, message: boolean | string) => void;
};

interface CustomArray<T1, T2 extends T1, T3 extends {}> extends Array<T2 & T3> {
    push(...items: T1[]): number;
    unshift(...items: T1[]): number;
    add(item: T1): T2 & T3
    reset(items: T1[]): void
}

interface Ioptions<ItemType, ItemTypeReady> {
    initItem?: (item: ItemType & ItemTypeReady) => ItemType & ItemTypeReady,
    singleCheck?: boolean
}

function useList<ItemType extends {}, ItemTypeReady extends {} = ItemType>(options?: Ioptions<ItemType, ItemTypeReady & ListItemType<ItemType, ItemTypeReady>>): CustomArray<ItemType, ListItemType<ItemType, ItemTypeReady>, ItemTypeReady & ListItemType<ItemType, ItemTypeReady>> {
    const [list, setList] = useState<(ItemType & ItemTypeReady)[]>([]);

    const initItem = (item: ItemType & ItemTypeReady & ListItemType<ItemType, ItemTypeReady>, target: any[], action: 'select' | 'update' | 'insert'): ItemType => {
        if (item && typeof item === 'object') {
            if (!item.$key) item.$key = "i" + Math.random().toString(16).slice(2);
            if (action === 'insert') {
                item.$original = JSON.parse(JSON.stringify(item));
            }

            item.bind = createBindFunction(item, item.$errors, (elm, name) => {
                if (item.$errors) delete item.$errors[name];
                setList([...target])
            });

            item.reset = () => {
                item.set({ ...(JSON.parse(JSON.stringify(item.$original))), $errors: {} });
                return item;
            }

            item.deleteFromTheList = () => {
                setList([...target].filter(elm => elm !== item));
            }
            item.setError = (name: string, message: boolean | string) => {
                item.$errors ??= {};
                if (message === false) {
                    delete item.$errors[name];
                } else {
                    item.$errors[name] = message
                }
            }
            item.set = (data?: any) => {
                if (data) Object.assign(item, data);
                setList([...target]);
            }
            if (!('$checked' in item)) {
                Object.defineProperty(item, "$checked", {
                    get: () => {
                        return (item as any).$_checked ?? false;
                    },
                    set: (value: boolean) => {
                        if (value && options && options.singleCheck) {
                            (item as any).$_checked = value;
                            setList((t) => {
                                t.forEach((elm: any) => {
                                    elm.$_checked = false;
                                    if (elm === item) {
                                        elm.$_checked = true;
                                    }
                                })
                                return [...t];
                            });
                        } else {
                            (item as any).$_checked = value;
                            (item as any).set();
                        }
                    },
                });
            }
            item.check = (checked: boolean) => {
                item.$checked = checked;
            }
            item.toggleCheck = () => {
                item.$checked = !item.$checked;
            }
            item.toJSON = () => {
                const elm = {
                    ...item,
                    $checked: (item as any).$_checked ?? false
                };
                delete (elm as any).$_checked
                delete (elm as any).$errors
                delete (elm as any).$saved
                delete (elm as any).$original
                return elm;
            }
            if (options && options.initItem) {
                options.initItem(item);
            }
        }
        return item;
    }

    const handler = {
        get(target: any, prop: any) {
            if (typeof prop === 'string' && prop.match(/^[0-9]+$/) && target[prop]) {
                return initItem(target[prop], target, 'select');
            }
            return target[prop];
        },
        set(target: any, prop: any, value: any) {
            if (['add', 'getCheckedList', 'reset'].includes(prop)) {
                target[prop] = value;
                return true;
            }
            if (typeof prop === 'string' && prop.match(/^[0-9]+$/)) {
                if (!target[prop]) {
                    if (value) {
                        if (target.find((elm: any) => elm === value)) {
                            value = JSON.parse(JSON.stringify(value));
                        }
                        value.$key = "i" + Math.random().toString(16).slice(2);
                    }
                }
                value = initItem(value, target, target[prop] ? 'update' : 'insert');
            }
            target[prop] = value;
            setList([...target]);
            return true;
        }
    };

    const proxy = new Proxy(list, handler);

    proxy.add = (item: ItemType) => {
        proxy.push(item);
        return proxy[proxy.length - 1];
    }

    proxy.reset = (items: ItemType[]) => {
        proxy.splice(0, proxy.length)
        proxy.push(...items);
    }

    proxy.getCheckedList = () => proxy.filter((elm: ListItemType<ItemType, ItemTypeReady>) => elm.$checked);

    return proxy;
}

export default useList;