import React from 'react';
import './Style.scss';

interface ILabelProps {
  label?: string | boolean;
  error?: string | boolean;
  span?: string | number;
  children: React.ReactNode;
}

function Label(props: ILabelProps) {
  const style = props.span ? { gridColumn: 'span ' + props.span } : {};
  return (
    <div className={`label-input ${props.error ? 'has-error' : ''}`} style={style}>
      {props.label ? <label>{props.label === true ? '' : props.label}</label> : null}
      {props.children}
    </div>
  );
}

export default Label;
