import React, { MouseEventHandler } from 'react';
import { Icon } from '..';
import styled from 'styled-components';
import './Style.scss';

interface IButtonProps {
  type?: "Primary" | "Default"
  background?: string;
  color?: string;
  className?: string;
  span?: number | string;
  padding?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

function Button({ children, type, icon, span, padding, ...props }: IButtonProps) {

  let iconContent = null
  if (icon) {
    switch (typeof icon) {
      case 'string':
        iconContent = <Icon icon={icon} />
        break;
      default:
        iconContent = icon
        break;
    }
  }

  return (
    <SButton span={span} hasIconWithContent={!!(iconContent && children)} hasIconOnly={!!(iconContent && !children)} background={type?.toLowerCase() === 'primary' ? 'var(--primary-blue, #0086c5)' : '#dfe4e9'} color={type?.toLowerCase() === 'primary' ? '#fff' : '#222'} style={{ padding }} {...props}  >
      {iconContent}
      {children}
    </SButton>
  );
}

const SButton = styled.button<{ background: string, color: string, hasIconWithContent: Boolean, hasIconOnly: Boolean, span?: string | number }>`
  all: unset;
  background-color: ${(props) => props.background};
  -webkit-border-radius: 3;
  -moz-border-radius: 3;
  border-radius: 3px;
  ${(props) => props.span ? 'grid-column: span ' + props.span +';': ''}
  color: ${(props) => props.color};
  padding: ${(props) => props.hasIconWithContent ? '10px 20px 10px 10px' : (props.hasIconOnly ? '10px' : '10px 20px 10px 20px')};
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  &:hover {
    filter: brightness(90%);
  }
  &:active {
    filter: brightness(50%);
  }
`;

export default Button;
