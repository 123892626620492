import { createContext, useContext, useEffect, useRef, useState } from "react";
import backend from "services/backend";
import { FormLayout, useBindableState, Input, Button, Modal, IModalRef, UploadImage, FormSpan, UploadImages } from "shared";

interface IAppForm {
    title: string
    logo?: string
}
export interface IApp extends IAppForm {
    appid: string
}

export type IAppState = IApp | null

export interface IAppRespState {
    app: IApp
}

export interface IAppRespContext {
    showCreateNewAppModal: () => void,
    reloadAppsData: () => void,
    loadingApps: boolean
    list: IApp[]
}

export async function loadAppData(appid: string): Promise<IAppState> {
    const { data } = await backend.post<IAppRespState>('/get-app', { appid })
    return data.app || null
}

export const AppsManagerContext = createContext<IAppRespContext>({
    showCreateNewAppModal: () => null,
    reloadAppsData: () => null,
    loadingApps: false,
    list: []
});

export function useAppsManager() {
    return useContext(AppsManagerContext);
}


export function AppsManager({ children }: any) {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingApps, setLoadingApps] = useState<boolean>(false);
    const [list, setList] = useState<IApp[]>([]);
    const modalRef = useRef<IModalRef>(null);
    const { bind, setErrors, getData } = useBindableState<{ app: IAppForm }>()

    const handleCreateApp = async () => {
        setLoading(true);
        setErrors({})
        backend.post<{ success: boolean }>('create-app', await getData())
            .then(({ data }) => {
                if (data.success) {
                    return reloadAppsData()
                }
            })
            .catch(({ response: { data: { error } } }) => {
                setErrors(error)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const showCreateNewAppModal = async () => {
        modalRef.current?.show({
            header: <h3 style={{ margin: 0 }}>Create new App</h3>,
            footer: (
                <>
                    <Button onClick={handleCreateApp} type="Primary" >Create app</Button>
                    <Button onClick={modalRef.current.hide}>Cancel</Button>
                </>
            )
        })
    }

    const reloadAppsData = () => {
        // setLoadingApps(true);
        // return backend.post<{ apps: IApp[] }>('apps')
        //     .then(({ data }) => {
        //         setList(data.apps)
        //         return data.apps;
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     })
        //     .finally(() => {
        //         setLoadingApps(false);
        //     })
    }

    useEffect(() => {
        reloadAppsData();
    }, [])

    return (
        <AppsManagerContext.Provider value={{ showCreateNewAppModal, reloadAppsData, loadingApps, list }}>
            {children}
            <Modal
                ref={modalRef}
                closeButton
                loading={loading}
            >
                <FormLayout columns="3" gap="15" >
                    <Input span={3} label="Title" placeholder="App Title" {...bind('app.title')} />
                </FormLayout>
            </Modal>
        </AppsManagerContext.Provider>
    )
}

export default useAppsManager;
