import { ReactNode } from "react"

interface IIconProps {
    icon: string | ReactNode
    size?: string | number
    onClick?: () => void
}

function Icon({ icon, onClick, size }: IIconProps) {
    if (typeof icon === 'string') {
        return (
            <span className="material-symbols-outlined" onClick={onClick} style={{ fontSize: size }}>
                {icon}
            </span>
        )
    }
    return <>{icon}</>;
}

interface IButtonIconProps extends IIconProps {
    className?: string
    icon: string | ReactNode
}

export function IconButton({ icon, ...props }: IButtonIconProps) {
    return (
        <button style={{ all: 'unset', cursor: 'pointer' }} {...props} ><Icon icon={icon} /></button>
    )
}

export default Icon
