import { useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import './Auth.scss';
import GoogleImage from './img/google.png'
import Input from './../Form/Input';


// function InputText({ type, value, label, name, validation, onChange, placeholder, children }: any) {
//     return (
//         <div className={`input-auth-elm ${validation && validation.valid === false ? 'input-auth-elm-has-error': ''} `}>
//             <label>{label}</label>
//             <input type={type || "text"} value={value || ''} name={name} onChange={onChange} placeholder={placeholder} />
//             {validation && validation.message ? <small>{validation.message}</small> : null}
//             {children}
//         </div>
//     )
// }

// function InputPassword(props: any) {
//     return <InputText type="password" {...props} />
// }


function Auth({ header, active = 'Login', onLogin }: any) {
    const [emailSent, setEmailSent] = useState(false);
    const { bind, ...formData } = useForm();

    let form = null;
    switch ((active || '').toLowerCase()) {
        case 'signup':
            form = (
                <>
                    <SepText>Sign up</SepText>
                    <Input label="Name" name="firstName" {...bind('name')} />
                    <Input label="Email" type="email" name="email" {...bind('email')} />
                    <Input type="password" label="Password" {...bind('password')} />
                    <Input type="password" label="Confirm Password" {...bind('confirmpassword')} />
                    <div className="auth-form-actions">
                        <button className="auth-btn">Sign up</button>
                        <small>You have an account? <Link to="../login">Login</Link></small>
                    </div>
                </>
            );
            break;
        case 'resetpassword':
            form = (
                <>
                    <SepText>Reset Password</SepText>
                    {
                        emailSent ?
                            (
                                <div className="success-alert">
                                    We have e-mailed your password reset link!
                                </div>
                            ) : (
                                <>
                                    <Input label="Email" type="email" name="email" {...bind('email')} />
                                    <div className="auth-form-actions">
                                        <button className="auth-btn" >Reset</button>
                                        <small>You have an account? <Link to="../login">Login</Link></small>
                                    </div>
                                </>
                            )
                    }

                </>
            );
            break;
        default:
            form = (
                <>
                    <SepText>Login</SepText>
                    <Input leftIcon="person" label="Email" type="email" name="email" {...bind('login')} />
                    <Input leftIcon="key" type="password" label="Password" {...bind('password')} />
                    <small><Link to="../reset-password">Forgot password?</Link></small>
                    <div className="auth-form-actions">
                        <button className="auth-btn" onClick={() => onLogin(formData.values, formData)}>Login</button>
                        <small>Don't have an account? <Link to="../signup">Sign up</Link></small>
                    </div>
                </>
            );
            break;
    }

    return (
        <div className="auth-page">
            <div className="auth-form">
                <div className="auth-logo">
                    {header}
                </div>
                {form}
                <SepText>OR</SepText>
                <button className="auth-SSO-btn" > <img src={GoogleImage} alt="google" /> <span>Continue with Google</span> </button>
            </div>
        </div>
    );
}

function SepText({ children }: any) {
    return (
        <div className="sep-text">
            <span className="sep-text-line" />
            <span className="sep-text-content" >{children}</span>
            <span className="sep-text-line" />
        </div>
    )
}

function Login(props: any) {
    return <Auth active="Login" {...props} />;
}

function SignUp(props: any) {
    return <Auth active="SignUp" {...props} />;
}

function ResetPassword(props: any) {
    return <Auth active="ResetPassword" {...props} />;
}

export function AuthRoute(props: { header?: any, onLogin: Function }) {
    return (
        <Routes>
            <Route path="/login" element={<Login {...props} />} />
            <Route path="/signup" element={<SignUp  {...props} />} />
            <Route path="/reset-password" element={<ResetPassword  {...props} />} />
            <Route path="*" element={<Navigate to="login" />} />
        </Routes>
    );
}

function useForm() {
    const [values, _setValues] = useState<any>({});
    let [errors, setErrors] = useState<any[]>([]);

    const bind = (name: any, { emptyValue = '' } = {}) => {
        let value = values[name] ?? null;
        if ([undefined, null].includes(value)) {
            value = emptyValue;
        }
        return {
            error: errors.find(err => err.name === name)?.message ?? false,
            value,
            onChange: (val: any) => {
                let v = null;
                if (val && typeof val === 'object' && 'target' in val) {
                    if ('type' in val.target && ['checkbox'].includes(val.target.type.trim().toLowerCase())) {
                        v = val.target.checked;
                    } else {
                        v = val.target.value;
                    }
                } else {
                    v = val;
                }
                values[name] = v;
                _setValues({ ...values });
            }
        }
    }

    const setError = (name: any, message = '') => {
        errors = errors.filter(err => err.name !== name);
        errors.push({ name, message, valid: false });
        setErrors(errors);
    }

    const isValid = () => {
        return errors.length === 0;
    }

    const resetErrors = (errorsList?: any) => {
        errors = [];
        if(errorsList && typeof errorsList === 'object') {
            Object.keys(errorsList).forEach(name => errors.push({ name, message: errorsList[name], valid: false }))
        }
        setErrors(errors);
    }

    const setValue = (name: any, value: any) => {
        values[name] = value;
        _setValues((v: any) => ({ ...v, [name]: value }));
    }

    const setValues = (data: any) => {
        _setValues({ ...data });
    }

    return { bind, values, setError, isValid, errors, resetErrors, setValue, setValues };
}


export default Auth;
