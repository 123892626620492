import { useState } from 'react';

function set<T extends object>(object: T, path: string, value: any): T {
  const obj = object as any;
  let pathList: string[] = path.split('.')
  const currentProp: string = pathList.shift() as string;
  if (pathList.length) {
    if (typeof obj[currentProp] !== 'object') {
      obj[currentProp] = {};
    }
    obj[currentProp] = set(obj[currentProp], pathList.join('.'), value);
  } else {
    obj[currentProp] = value;
  }
  return obj;
}

function get<T extends object>(obj: T, path: string, value?: any): any {
  const object = obj as any;
  let pathList: string[] = path.split('.')
  const currentProp: string = pathList.shift() as string;
  if (pathList.length) {
    if (typeof object[currentProp] === 'object') {
      return get(object[currentProp], pathList.join('.'), value);
    }
  } else {
    return object[currentProp] ?? value;
  }
  return value;
}

export interface IUseBindableState<T = any> {
  data: T | undefined;
  bind: (name: string, options?: IOptions) => {
    value?: any;
    checked?: any;
    error: any;
    onChange: (e: any) => void;
  };
  errors: any;
  setError: (name: string, message?: string | Boolean) => void;
  setErrors: (errors: any) => void;
  setData: React.Dispatch<React.SetStateAction<T | undefined>>;
  getData: () => Promise<T>;
  set: (name: string, value: any) => void
  get: (name: string, value: any) => void
}

export interface IBindOptions {
  checkbox?: boolean | [ any, any? ]
  emptyValue?: any
}

function structuredClone_(data: any) {
  return JSON.parse(JSON.stringify(data));
}

export function createBindFunction(data: any, errors: any, onSet: (v: any, name: string) => void) {
  return (name: string, options: IBindOptions = {}) => {
    let value = get(data, name, undefined)

    const getCheckBoxValue = (checked: boolean) => {
      if (Array.isArray(options.checkbox) && options.checkbox.length) {
        const [tValue, fValue = false] = options.checkbox;
        return checked ? tValue : fValue;
      }
      return !!checked;
    }

    if (options.checkbox) {
      if (Array.isArray(options.checkbox) && options.checkbox.length) {
        const [tValue] = options.checkbox;
        value = value === tValue;
      } else {
        value = Boolean(value);
      }
    }



    if ([undefined, null, ''].includes(value)) {
      value = options.emptyValue ?? (options.checkbox ? false : '')
    }

    return {
      [options.checkbox ? 'checked' : 'value']: value,
      error: errors ? (errors[name] ?? false) : false,
      onChange: (e: any) => {
        const getValue = () => {
          let value = options.checkbox ? (e?.target?.checked ?? !!e) : (e?.target?.value ?? e);
          if (options.checkbox) return getCheckBoxValue(value);
          if ([undefined, null, ''].includes(value)) {
            value = options.emptyValue ?? (options.checkbox ? false : '')
          }
          return value;
        }
        set(data, name, getValue());
        onSet((s: any) => set(structuredClone_(s), name, getValue()), name);
      },
    };
  }
}

function useBindableState<T extends object>(initalData?: T): IUseBindableState<T> {
  const [data, setData] = useState<T | undefined>(initalData);
  const [errors, setErrors] = useState<any>({});

  const bind = createBindFunction(data || {}, errors, (d: any, name: string) => {
    setData(d);
    setErrors((err: any) => ({ ...err, [name]: undefined }));
  });

  const setError = (name: string, message?: string | Boolean) => {
    setErrors((err: any) => ({ ...err, [name]: message ?? true }));
  }

  const _set = (name: string, value: any) => {
    setData((s: T | undefined) => set(structuredClone_(s || {}), name, value));
  }

  const _get = (name: string, value?: any) => get(data || {}, name, value)

  const getData = () => {
    return new Promise<T>((resolve) => {
      setData(s => {
        setTimeout(() => resolve(s as T), 1);
        return s;
      });
    })
  }

  return { data, bind, errors, setError, setErrors, setData, set: _set, get: _get, getData };
}

export default useBindableState;
