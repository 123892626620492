import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import userState from './../states/userState';
import backend from './../services/backend';

interface IUser {
  id: string;
  name: string;
  photo: string;
  username: string;
  uid: string;
  login: string;
}

interface IUserProps {
  check: Function;
  loading: Boolean;
  user: IUser | null;
  setUser: Function;
  logout: Function;
}

function useUser(autoCheck: boolean = false): IUserProps {
  const [user, setUser]: [IUser | null, any] = useRecoilState<any>(userState);
  const [loading, setLoading] = useState<Boolean>(autoCheck);
  
  const check = () => {
    setLoading(true);
    backend
      .get<{ user: IUser }>('/auth/me')
      .then((res) => res.data)
      .then((data) => {
        if (data.user) {
          setUser(data.user);
        } else {
          logout()
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (autoCheck && !user) {
      check();
    }
  }, [autoCheck]);

  const logout = () => {
    setUser(null);
    localStorage.clear();
  }

  return { check, loading, user, setUser, logout };
}

export default useUser;
