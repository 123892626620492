import { Routes, Route, Navigate } from 'react-router-dom';
import useUser from './hooks/useUser';
import Layout, { LayoutApp } from './layout/Layout';
import { usePOST } from 'hooks/useAPI';
import { useEffect } from 'react';
import Apps from './pages/Apps';
import AppDetail from 'pages/AppDetail';
import { AuthRoute, Loading, Logo, MyNavigate } from 'shared';



function AuthPage() {
  const { loading, result, send } = usePOST<{ success: boolean; token: string }>('/auth/login');

  const { check } = useUser();

  useEffect(() => {
    if (result) {
      localStorage.setItem('token', result.token);
      check();
    }
  }, [result]);

  const onLogin = (data: any, { resetErrors }: any) => {
    send(data, resetErrors)
  }

  return (
    <Loading loading={loading || result?.token}>
      <AuthRoute header={<Logo height={50} />} onLogin={onLogin} />
    </Loading>
  );
}

function App() {
  const { user, loading } = useUser(true);
  if (loading) return <Loading loading />;

  if (!user) {
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthPage />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path='/app/:appid' element={<LayoutApp />}>
        <Route path="/app/:appid" element={<AppDetail />} />
        <Route path="/app/:appid/test" element={<AppDetail />} />
        <Route path="/app/:appid/*" element={<MyNavigate to={`/app/:appid`} />} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/auth/*" element={<Navigate to="/" />} />
        <Route path="/" element={<Apps />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

export default App;
