import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

(window as any).api = (name: string, data: any = {}) => api.post(name, data)
  .then(res => res.data)
  .then(d => {
    if (d && Array.isArray(d.logs)) {
      d.logs.map((logs: any) => console.log(...logs))
    } else {
      console.log(d);
    }
    return d;
  });

export default api;
