import RSelect from 'react-select';
import { IInputWrapperProps, InputWrapper } from './Input';
import { ReactNode } from 'react';

export const colourOptions: readonly any[] = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

interface ISelectOption {
    value: string | number
    label: ReactNode | string
    color?: string
    isFixed?: boolean
    isDisabled?: boolean
}

interface ISelectProps extends IInputWrapperProps {
    placeholder?: string;
    value?: string | number;
    onChange?: (event: any) => void
    options: (ISelectOption | string)[]
}

function Select({ value, onChange, options, ...props}: ISelectProps) {

    const _options: ISelectOption[] = options.map((option) => (typeof option === 'string' ? { value: option, label: option } : option))

    return (
        <InputWrapper {...props}>
            <RSelect
                className="react-select"
                classNamePrefix="select"
                value={_options.find((option) => option.value === value)}
                onChange={(item: any) => onChange ? onChange(item ? item.value : item) : null}
                options={_options}
            />
        </InputWrapper>
    )
}

export default Select
