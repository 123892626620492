import { useSelectedAPP } from 'hooks/useSelectedAPP';
function AppDetail() {
  const app = useSelectedAPP();
  return (
    <div>
      <h2>State : </h2>
      <pre>{JSON.stringify(app, null, 2)}</pre>
    </div>
  )
}

export default AppDetail
