import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import './Style.scss';

interface IFormLayoutProps {
    columns?: string
    gap?: string
    inline?: boolean
    span?: string | number
}

function FormLayout({ children, ...props }: React.PropsWithChildren<IFormLayoutProps>) {
    return (
        <Container {...props}>
            {children}
        </Container>
    )
}

export function FormSpan({ span, children }: PropsWithChildren<{ span: string | number }>){
    return <div style={{ gridColumn: 'span '+ span }} >{children}</div>
}

const Container = styled.div<IFormLayoutProps>`
    display: ${(props) => props.inline ? 'inline-grid' : 'grid'};
    grid-template-columns: repeat(${(props) => props.columns ?? '1'}, 1fr);
    grid-column-gap: ${(props) => props.gap ?? '15'}px;
    grid-row-gap: ${(props) => props.gap ?? '15'}px;
    ${(props) => props.span ? 'grid-column: span ' + props.span +';': ''}
`;

export default FormLayout
