import { Icon, IconButton, Logo } from 'shared';
import useUser from 'hooks/useUser';
import styled from 'styled-components';
import './SideBar.scss';

function SideBar() {
    const { logout } = useUser();
    return (
        <div className="SideBar">
            <div className='header'>
                <Logo color='#ffffffe1' color2='#ffffffb2' />
                <IconButton icon="menu" />
            </div>
            <div className='list'>
                <button className='link'> <Icon icon='settings' /> Customer Management</button>
            </div>
            <SideBarSep />
            <div className='list'>
                <button className='link' onClick={logout}> <Icon icon='logout' /> Logout</button>
            </div>
        </div>
    )
}

const SideBarSep = styled.div`
    flex: 1;
`;

export default SideBar
