import Header from 'components/Header/Header';
import { Outlet } from 'react-router-dom';
import SideBar from '../components/SideBar/SideBar';
import styled from 'styled-components';
import { SelectedAPP } from 'hooks/useSelectedAPP';
import { AppsManager } from 'hooks/useAppsManager';

const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-gray);
  max-height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
`;

function Layout() {
  return (
    <AppsManager>
      <div className='Layout'>
        <SideBar />
        <Main>
          <Header />
          <Container>
            <Outlet />
          </Container>
        </Main>
      </div>
    </AppsManager>
  )
}

export function LayoutApp() {
  return (
    <SelectedAPP>
      <div className='Layout'>
        <SideBar />
        <Main>
          <Header />
          <Container>
            <Outlet />
          </Container>
        </Main>
      </div>
    </SelectedAPP>
  )
}

export default Layout
